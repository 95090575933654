import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


import DownLoad from './component/downLoad/DownLoad'; // 官网 - 印尼
function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<DownLoad />} />
                </Routes>
            </Router>
        </div>
    );
}


export default App;
