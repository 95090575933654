import styled from 'styled-components'

export const Wrapper = styled.div`

    .downLoad {
        max-width: 750px;
        width: 100%;
        margin: 0 auto;

        .topDownLoad {

            .downloadPage1 {
                width: 100%;
            }
        }

        .downloadPage {
            position: absolute;
            z-index: 11;
            top: 0;
            left: 0;
            border: none;
            margin-top: 12rem;
            margin-left: 1.1rem;
            width: 16.5rem;
            height: 2.3rem;
            line-height: 2.3rem;
            background: #3b8562;
            border-radius: 0.3rem;
            color: #FFF;
            font-size: 1rem;
            text-align: center;
        }


    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9999; /* 确保蒙版层在其他元素之上 */
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .content {
            background: #ffffff;
            border-radius: 20px;
            padding: 20px;
        }
    }
`