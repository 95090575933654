import React, {Component} from 'react';
import {$http, Qs, setTitle} from '../../tools';
import downloadPage from './../../images/download.jpg'
import {v4 as uuidv4} from 'uuid';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

import {Wrapper} from "./downLoad_style";
import {Dialog, Modal, ProgressCircle, Toast} from "antd-mobile";
import axios from "axios";

var params = Qs();
var deviceId2 = ""
var version = ""

export default class downLoad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apkUrl: '',
            urlMaps: {},
            progress: 0,
            showDownloadPop: false
        }
    }

    componentDidMount() {
        setTitle('Tunai Cepat');
        this.getapkUrl();
        // this.getDeviceId();
        // this.getDeviceId2().then(res => {
        // });
        // this.bpoint(1)
        this.bpoint2(params["appMarket"], "init")

    }

    getapkUrl() {
        $http.get(`/download.json`, {}).then(res => {

            let url = (params["appMarket"] || "tc01") + "_url"
            this.setState({
                apkUrl: res[url],
                urlMaps: res
            });
        });
    }

    async bpoint(type, message) {
        var res = await $http.post(`/wow/hope/landingPage`, {
            'type': type,
            'user_pv_id': this.getDeviceId(),
            'user_pvv_id': deviceId2,
            'message': message
        })

        // console.log(res)
    }

    async bpoint2(appMarket, event) {

        // https://download.tunai-cepat.com/tc/service/event/d?appMarket=tc01&event=click
        //     https://download.tunai-cepat.com/tc/service/event/d?appMarket=tc01&event=init

        var res = await $http.get(`/tc/service/event/d?appMarket=${appMarket}&event=${event}`, {})

        console.log(res)
    }

    getDeviceId() {
        let deviceId = localStorage.getItem('myUniqueUUID');
        if (!deviceId) {
            deviceId = uuidv4();
            localStorage.setItem('myUniqueUUID', deviceId);
        }
        return deviceId;
    }

    async getDeviceId2() {
        deviceId2 = localStorage.getItem('generateFingerprint');
        if (!deviceId2) {
            const fpPromise = FingerprintJS.load({apiKey: 'aK7nnewi8mV6M774rdZU'});
            const fp = await fpPromise;
            const result = await fp.get()
            console.log("获取指纹：" + result.visitorId)
            deviceId2 = result.visitorId
            localStorage.setItem('generateFingerprint', result.visitorId);
        }
        return deviceId2;
    }

    async goDownloadApp2() {
        const {apkUrl} = this.state;

        if (apkUrl) {
            console.log('用户点击了下载链接');
            this.bpoint(2);
            this.setState({
                progress: 0,
                showDownloadPop: true
            })
            try {
                const response = await axios.get(apkUrl, {
                    responseType: 'blob',
                    onDownloadProgress: (progressEvent) => {
                        const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        console.log(`下载进度: ${progress}%`);
                        this.setState({
                            progress: progress,
                        })
                        // 这里你可以将进度展示在界面上，比如更新下载进度条
                    },
                });

                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = apkUrl;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                this.bpoint(3);
            } catch (error) {
                console.error('下载 APK 出错:', error);
                this.bpoint(4, `'下载 APK 出错:'${error}`);
            }
        } else {
            this.bpoint(4, 'APK 下载链接不可用');
        }

        this.setState({
            showDownloadPop: false
        })
        await Dialog.alert({
            confirmText: "Confirm",
            content: 'The file download is complete, please go to the browser download list to view and install!',
            onConfirm: () => {
                console.log('Confirmed')
            },
        })
    }

    async goDownloadApp3() {
        const {apkUrl} = this.state;
        // this.bpoint(2);
        this.bpoint2(params["appMarket"], "click")
        window.fbq('track', 'Purchase', {currency: "USD", value: 0});
        window.location.href = apkUrl;
    }

    componentWillUnmount() {
    }


    render() {

        return (
            <Wrapper>
                <div className="downLoad">
                    <div className="topDownLoad">
                        <img className="downloadPage1" src={downloadPage} alt=""/>

                    </div>
                    {/*<a className="downloadPage" href={this.state.apkUrl}  onClick={this.goDownloadApp.bind(this)}>install</a>*/}
                    <button className="downloadPage" onClick={this.goDownloadApp3.bind(this)}>install</button>
                </div>

                {
                    this.state.showDownloadPop ?
                        <div className='overlay'>
                            <div className='content'>
                                <ProgressCircle percent={this.state.progress}>{this.state.progress}%</ProgressCircle>
                                <div>loading...</div>
                                <div>Tip: Please use a major browser to download</div>
                            </div>
                        </div> : <div></div>
                }

            </Wrapper>

        );
    }
}

